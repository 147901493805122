/*
| Developed by Dirupt
| Filename : ButtonUploadInput.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Accept, DropzoneProps, useDropzone } from 'react-dropzone';
import { Box, BoxProps, Button, ButtonProps, FormControl, FormControlProps, FormHelperText, FormLabel, styled, Typography } from '@mui/material';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from 'formik';
import _ from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import { MTypographyUnderline } from '@/components/common/MTypographyUnderline';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface ButtonUploadInputPropsChildren {
  field: FieldInputProps<Array<File>>;
  meta: FieldMetaProps<Array<File>>;
  helpers: FieldHelperProps<Array<File>>;
  onDelete: (index: number) => void;
  previews: Array<string>;
}
export interface ButtonUploadInputProps {
  name: string;
  buttonProps: ButtonProps;
  buttonComponent?: React.ElementType;
  children: React.FC<ButtonUploadInputPropsChildren>;
  label?: string;
  accept?: Accept;
  maxFiles?: number;
  maxSize?: number;
  helperText?: string;
  showChildrenWhenEmpty?: boolean;
  dropzoneProps?: Omit<DropzoneProps, 'accept' | 'maxFiles' | 'maxSize'>;
  buttonContainerProps?: BoxProps;
  formControlProps?: FormControlProps;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const DropzoneContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDragActive' && prop !== 'isEmpty'
})<BoxProps & {
  isDragActive?: boolean;
  isEmpty: boolean;
}>(({
  theme,
  isDragActive,
  isEmpty
}) => ({
  borderWidth: 2,
  borderStyle: 'dashed',
  margin: theme.spacing(1, 0, 1),
  borderRadius: 12,
  boxSizing: 'border-box',
  ...(isEmpty ? {
    padding: theme.spacing(4, 2)
  } : {}),
  ...(isDragActive ? {
    borderColor: theme.palette.primary.main
  } : {
    borderColor: isEmpty ? theme.palette.divider : 'transparent'
  })
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ButtonUploadInput: React.FC<ButtonUploadInputProps> = ({
  children,
  name,
  buttonProps,
  buttonComponent: ButtonComponent = Button,
  label,
  accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg']
  },
  maxFiles = 1,
  maxSize = 50 * 1024 * 1024,
  // 50MB
  helperText,
  showChildrenWhenEmpty = false,
  dropzoneProps,
  buttonContainerProps,
  formControlProps
}) => {
  const {
    t
  } = useTranslation();
  const [field, meta, helpers] = useField<Array<File>>(name);
  const [filePreview, setFilePreview] = React.useState<Array<string>>([]);
  const {
    error,
    showError
  } = React.useMemo(() => {
    const showError = meta.touched;
    const error = typeof meta.error === 'string' ? meta.error : _.get(meta.error, name);
    return {
      error,
      showError
    };
  }, [meta.error, meta.touched, name]);
  const isEmpty = React.useMemo(() => {
    if (showChildrenWhenEmpty) return false;
    return field.value.length === 0;
  }, [field.value, showChildrenWhenEmpty]);

  // Methods
  // ----------------------------------------------------------------------------
  const onDelete = React.useCallback(async (index: number) => {
    // Set file preview
    const newFilePreview = [...filePreview];
    newFilePreview.splice(index, 1);
    setFilePreview(newFilePreview);

    // Revoke object URL
    URL.revokeObjectURL(filePreview[index]);
    const newFiles = [...field.value];
    newFiles.splice(index, 1);
    await helpers.setValue(newFiles);
  }, [field.value, filePreview, helpers]);

  // Dropzone configuration
  // ----------------------------------------------------------------------------
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    fileRejections
  } = useDropzone({
    onDrop: async (acceptedFiles: Array<File>) => {
      await helpers.setValue([...field.value, ...acceptedFiles]);

      // Set file preview
      const newFilePreview = [...filePreview];
      for (const file of acceptedFiles) {
        newFilePreview.push(URL.createObjectURL(file));
      }
      setFilePreview(newFilePreview);
    },
    accept,
    maxFiles,
    maxSize,
    noClick: field.value.length > 0,
    ...dropzoneProps
  });

  // Render
  // ----------------------------------------------------------------------------
  const childProps = React.useMemo<ButtonUploadInputPropsChildren>(() => ({
    field,
    meta,
    helpers,
    onDelete,
    previews: filePreview
  }), [field, meta, helpers, onDelete, filePreview]);
  return <FormControl {...formControlProps} error={showError || fileRejections.length > 0 ? Boolean(error) : undefined} data-sentry-element="FormControl" data-sentry-component="ButtonUploadInput" data-sentry-source-file="ButtonUploadInput.tsx">
			{label ? <FormLabel htmlFor={name}>
					<MTypographyUnderline>{label}</MTypographyUnderline>
				</FormLabel> : null}

			<DropzoneContainer {...buttonContainerProps} {...getRootProps()} isDragActive={isDragActive} isEmpty={isEmpty} data-sentry-element="DropzoneContainer" data-sentry-source-file="ButtonUploadInput.tsx">
				<input {...getInputProps()} />
				{children(childProps)}
				{/* Show a message when user is dragging a file */}
				{isEmpty ? isDragActive ? <Typography>{t('common:form.upload.dragHere.multiple')}</Typography> : <Typography>{t('common:form.upload.dragAndDrop.multiple')}</Typography> : null}
			</DropzoneContainer>

			{/* Show all file rejections error, group by message and show only message without translation */}
			{fileRejections.length > 0 ? <FormHelperText error sx={{
      marginBottom: 1
    }}>
					{fileRejections.map(fileRejection => fileRejection.errors.map(error => t(`common:form.upload.errors.${error.code}`, {
        maxFiles: maxFiles,
        maxSize: maxSize / 1024 / 1024
      }))).flat().filter((value, index, self) => self.indexOf(value) === index).join('\n')}
				</FormHelperText> : null}

			<ButtonComponent variant={'outlined'} color={'primary'} fullWidth {...buttonProps} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      open();
      buttonProps && buttonProps.onClick && buttonProps.onClick(event);
    }} disabled={field.value.length >= maxFiles} data-sentry-element="ButtonComponent" data-sentry-source-file="ButtonUploadInput.tsx" />
			{showError && error || helperText ? <FormHelperText>{error || helperText}</FormHelperText> : null}
		</FormControl>;
};